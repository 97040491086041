<template>
    <div>
        <v-container class="paper-border">
            <v-row>
                <v-col cols="2" class="d-flex flex-column py-0">
                    <span class="font-weight-bold">{{ $t('message.seller') }}</span>
                    <span :class="Purchaseorder__Supplier__title ? '' : 'red--text'">{{ Purchaseorder__Supplier__title ? Purchaseorder__Supplier__title : $t('message.valueMissing') }}</span>
                </v-col>
                <v-col cols="2" class="d-flex flex-column py-0">
                    <span class="font-weight-bold">{{ $t('message.marketing') }}</span>
                    <span :class="Purchaseorder__Contract__salescontact_id ? '' : 'red--text'">{{ Purchaseorder__Contract__salescontact_id ? findSalesColleagueById(Purchaseorder__Contract__salescontact_id).Salesperson.name : $t('message.valueMissing') }}</span>
                </v-col>
                <v-col cols="2" class="d-flex flex-column py-0">
                    <span class="font-weight-bold">{{ $t('message.date') }}</span>
                    <span :class="Purchaseorder__podate ? '' : 'red--text'">{{ Purchaseorder__podate ? formatDate(Purchaseorder__podate) : $t('message.valueMissing') }}</span>
                </v-col>
                <v-col cols="2" class="d-flex flex-column py-0">
                    <span class="font-weight-bold">{{ $t('message.number') }}</span>
                    <span :class="Purchaseorder__title ? '' : 'red--text'">{{ Purchaseorder__title ? Purchaseorder__title : $t('message.valueMissing') }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" class="d-flex flex-column pt-1 pb-0">
                    <span class="font-weight-bold">{{ $t('message.buyer') }}</span>
                    <span :class="Purchaseorder__Office__title ? '' : 'red--text'">{{ Purchaseorder__Office__title ? Purchaseorder__Office__title : $t('message.valueMissing') }}</span>
                </v-col>
                <v-col cols="6" class="d-flex flex-column pt-1 pb-0">
                    <span class="font-weight-bold">{{ $t('message.deliveryAddressAndNotifyingParty') }}</span>
                    <span v-if="Purchaseorder__Contract__salestype_id != 5" :class="Purchaseorder__Customer__title != null ? null : 'red--text'">{{ Purchaseorder__Customer__title != null ? Purchaseorder__Customer__title : $t('message.valueMissing') }}</span>
                    <span v-if="Purchaseorder__Contract__salestype_id == 5" :class="Purchaseorder__Office__title ? '' : 'red--text'">{{ Purchaseorder__Office__title ? Purchaseorder__Office__title : $t('message.valueMissing') }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" class="d-flex flex-column pt-1 pb-0">
                    <span class="font-weight-bold">{{ $t('message.termsOfPayment') }}</span>
                    <span :class="Purchaseorder__paymentstatement_id ? '' : 'red--text'">{{ Purchaseorder__paymentstatement_id ? findSupplierPaymentTermById(Purchaseorder__paymentstatement_id).Paymentterm.title : $t('message.valueMissing') }}</span>
                </v-col>
                <v-col cols="6" class="d-flex flex-column pt-1 pb-0">
                    <span class="font-weight-bold">{{ $t('message.shipment') }}</span>
                    <span :class="Purchaseorder__shippingweekstatement ? '' : 'red--text'">{{ Purchaseorder__shippingweekstatement ? Purchaseorder__shippingweekstatement : $t('message.valueMissing') }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="3" offset-lg="6" class="d-flex flex-column pt-1 pb-0">
                    <span class="font-weight-bold">{{ $t('message.countryOriginDestination') }}</span>
                    <span :class="Purchaseorder__Contract__productorigin_id || Purchaseorder__Contract__destinatione_id ? '' : 'red--text'">
                        {{ originCountry != null ? originCountry : $t('message.valueMissing') }} /
                        {{ destinationCountry != null ? destinationCountry : $t('message.valueMissing') }}
                    </span>
                </v-col>
                <v-col cols="3" class="d-flex flex-column pt-1 pb-0">
                    <span class="font-weight-bold font-xs">{{ $t('message.termsOfDelivery') }}</span>
                    <span :class="poIncoterm ? '' : 'red--text'">
                        {{ poIncoterm ? poIncoterm : $t('message.valueMissing') }}
                    </span>
                </v-col>
            </v-row>
            <v-simple-table
                    class="pt-2"
                    dense
            >
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th :class="(Purchaseorder__Contract__currency_id != Purchaseorder__currency_id ? 'font-sm' : 'subtitle-2') + ' px-0 font-weight-black grey lighten-4'">{{ $t('message.species') }}</th>
                            <th :class="(Purchaseorder__Contract__currency_id != Purchaseorder__currency_id ? 'font-sm' : 'subtitle-2') + ' px-0 font-weight-black grey lighten-4'">{{ $t('message.specification') }}</th>
                            <th :class="(Purchaseorder__Contract__currency_id != Purchaseorder__currency_id ? 'font-sm' : 'subtitle-2') + ' px-0 font-weight-black grey lighten-4'">{{ $t('message.size') }}</th>
                            <th :class="(Purchaseorder__Contract__currency_id != Purchaseorder__currency_id ? 'font-sm' : 'subtitle-2') + ' px-0 font-weight-black width-10-pct grey lighten-4'">{{ $t('message.grade') }}</th>
                            <th :class="(Purchaseorder__Contract__currency_id != Purchaseorder__currency_id ? 'font-sm' : 'subtitle-2') + ' px-0 font-weight-black text-right width-10-pct grey lighten-4'">{{ $t('message.quantity') }}</th>
                            <th :class="(Purchaseorder__Contract__currency_id != Purchaseorder__currency_id ? 'font-sm' : 'subtitle-2') + ' px-0 font-weight-black text-right width-10-pct grey lighten-4'">{{ $t('message.unitPrice') + (Purchaseorder__Contract__currency_id != Purchaseorder__currency_id ? '(' + currencies.find((currency)=>currency.Currency.id == Purchaseorder__Contract__currency_id).Currency.code+')' : '')}}</th>
                            <th :class="(Purchaseorder__Contract__currency_id != Purchaseorder__currency_id ? 'font-sm' : 'subtitle-2') + ' px-0 font-weight-black text-right width-10-pct grey lighten-4'">{{ $t('message.amount') + (Purchaseorder__Contract__currency_id != Purchaseorder__currency_id ? '(' + currencies.find((currency)=>currency.Currency.id == Purchaseorder__Contract__currency_id).Currency.code+')' : '') }}</th>
                            <th class="font-sm px-0 font-weight-black text-right width-10-pct grey lighten-4" v-if="Purchaseorder__Contract__currency_id != Purchaseorder__currency_id">{{ $t('message.price') + '(' + currencies.find((currency)=>currency.Currency.id == Purchaseorder__currency_id).Currency.code + ')' }}</th>
                            <th class="font-sm px-0 font-weight-black text-right width-10-pct grey lighten-4" v-if="Purchaseorder__Contract__currency_id != Purchaseorder__currency_id">{{ $t('message.amt') + '(' + currencies.find((currency)=>currency.Currency.id == Purchaseorder__currency_id).Currency.code + ')' }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in PurchaseOrderItems" :key="index" v-if="item.type == 'default'">
                            <td :class="(item.Species.title == null ? 'red--text ' : '') + 'px-0 border-0'">{{ item.Species.title != null ? item.Species.title : $t('message.valueMissing') }}</td>
                            <td :class="(item.Spec.title == null ? 'red--text ' : '') + 'px-0 border-0'">{{ item.Spec.title != null ? item.Spec.title : $t('message.valueMissing') }}</td>
                            <td :class="(item.Size.title == null ? 'red--text ' : '') + 'px-0 border-0'">{{ item.Size.title != null ? item.Size.title : $t('message.valueMissing') }}</td>
                            <td :class="(item.Salesitem.pograde_id == null && item.type == 'default' ? 'red--text ' : '') + 'px-0 border-0'">{{ item.Salesitem.pograde_id != null  ? getPurchaseOrderGrade(item.Salesitem.pograde_id) : $t('message.valueMissing') }}</td>
                            <td class="px-0 text-right border-0">{{ formatThisNumber(item.Salesitem.poqty, uofmPrecision(item.Measurement.title)) + ' ' + uofmQty.find((uofm)=>uofm.Measurement.id == item.Salesitem.pomeasurement_id).Measurement.title }}</td>
                            <td class="px-0 text-right border-0">{{ formatThisNumber(item.Salesitem.costprice,currencyFormatPrecision) }}</td>
                            <td class="px-0 text-right border-0">{{ formatThisNumber((item.Salesitem.poqty * item.Salesitem.costprice),currencyFormatPrecision) }}</td>
                            <td class="px-0 text-right border-0" v-if="Purchaseorder__Contract__currency_id != Purchaseorder__currency_id">{{ formatThisNumber(item.Salesitem.costprice_converted,currencies.find((currency)=>currency.Currency.id == Purchaseorder__currency_id).Currency.formatPrecision) }}</td>
                            <td class="px-0 text-right border-0" v-if="Purchaseorder__Contract__currency_id != Purchaseorder__currency_id">{{ formatThisNumber(item.Salesitem.poamount_converted,currencies.find((currency)=>currency.Currency.id == Purchaseorder__currency_id).Currency.formatPrecision) }}</td>
                        </tr>
                        <tr v-else-if="item.type == 'othercharge'">
                            <td></td>
                            <td></td>
                            <td class="px-0">{{ item.Salesitem.size }}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="px-0 text-right border-0">{{ formatThisNumber(item.Salesitem.poamount,currencyFormatPrecision) }}</td>
                            <td v-if="Purchaseorder__Contract__currency_id != Purchaseorder__currency_id"></td>
                            <td class="px-0 text-right border-0" v-if="Purchaseorder__Contract__currency_id != Purchaseorder__currency_id">{{ formatThisNumber((item.Salesitem.amount * Purchaseorder__currencyrate),currencyFormatPrecision) }}</td>
                        </tr>
                        <tr>
                            <td class="border-top-2"></td>
                            <td class="border-top-2"></td>
                            <td class="border-top-2"></td>
                            <td class="px-0 font-weight-bold border-top-2">
                                <span class="pr-2">{{ $t('message.total') }}</span>
                                <span v-if=" [1,2,3,5].includes(Purchaseorder__Contract__containersize_id) ">{{ Purchaseorder__Contract__containercount }}</span>
                                <span v-if=" [1,2,3,5].includes(Purchaseorder__Contract__containersize_id) "> X </span>
                                <span>{{ containerSizes.find(containerSize => containerSize.Containersize.id == Purchaseorder__Contract__containersize_id).Containersize.title }}</span>
                            </td>
                            <td class="px-0 text-right font-weight-bold border-top-2">
                                <span v-for="(total,index) in itemsTotalQty">
                                        {{ formatThisNumber(total.Total.qty, uofmPrecision(total.Total.uofm)) + ' '+ total.Total.uofm }}
                                </span>
                            </td>
                            <td class="px-0 text-right font-weight-bold border-top-2" v-if="Purchaseorder__Contract__currency_id != Purchaseorder__currency_id">{{ Purchaseorder__currency_id != null ? currencies.find((currency) => currency.Currency.id == Purchaseorder__Contract__currency_id).Currency.code : $t('message.noCurrency') }}</td>
                            <td class="px-0 text-right font-weight-bold border-top-2" v-else>{{ Purchaseorder__currency_id != null ? currencies.find((currency) => currency.Currency.id == Purchaseorder__currency_id).Currency.code : $t('message.noCurrency') }}</td>
                            <td class="px-0 text-right font-weight-bold border-top-2">{{ formatThisNumber(itemsTotalAmount,currencyFormatPrecision) }}</td>
                            <td class="px-0 text-right font-weight-bold border-top-2" v-if="Purchaseorder__Contract__currency_id != Purchaseorder__currency_id">{{ currencies.find((currency) => currency.Currency.id == Purchaseorder__currency_id).Currency.code }}</td>
                            <td class="px-0 text-right font-weight-bold border-top-2" v-if="Purchaseorder__Contract__currency_id != Purchaseorder__currency_id">{{ formatThisNumber((itemsTotalAmount * Purchaseorder__currencyrate),currencyFormatPrecision) }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-row>
                <v-col cols="6" class="d-flex flex-column pb-0">
                    <span class="font-weight-bold">{{ $t('message.remarks') }}</span>
                    <span :class="Purchaseorder__shipremark != null ? '' : 'red--text'" style="white-space: pre; margin-left: -4px !important;">
                        {{ Purchaseorder__shipremark != null ? Purchaseorder__shipremark.trim() : $t('message.valueMissing') }}
                        {{ specificationNotes != null ? "\n" + specificationNotes : '' }}
                    </span>
                </v-col>
                <v-col cols="6" class="d-flex flex-column pb-0">
                    <span class="font-weight-bold">{{ $t('message.certification') }}</span>
                    <span :class="Purchaseorder__fsc ? '' : 'red--text'">
                    {{ Purchaseorder__fsc != null ? certifications.find((certification)=>certification.Certification.id == Purchaseorder__fsc).Certification.title : $t('message.valueMissing') }}
                    </span>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    import {mapActions, mapGetters} from "vuex";
    import {formatDate, numberFormat} from "../../helpers/helpers";
    import { api } from "../../api";
    import {mapFieldsPrefixed,mapMultiRowFieldsPrefixed} from  'Helpers/vuex-map-fields-prefixed'

    export default {
        name: "PurchaseOrderPreview",
        data(){
            return {

            }
        },
        computed: {
            statePrefix(){
                if(this.updateMode){
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapFieldsPrefixed('purchaseorder',{
                Purchaseorder__currency_id: 'Purchaseorder.currency_id',
                Purchaseorder__currencyrate: 'Purchaseorder.currencyrate',
                Purchaseorder__fsc: 'Purchaseorder.fsc',
                Purchaseorder__incoterm_id: 'Purchaseorder.incoterm_id',
                Purchaseorder__incotermport: 'Purchaseorder.incotermport',
                Purchaseorder__paymentstatement_id: 'Purchaseorder.paymentstatement_id',
                Purchaseorder__podate: 'Purchaseorder.podate',
                Purchaseorder__shippingweekstatement: 'Purchaseorder.shippingweekstatement',
                Purchaseorder__shipremark: 'Purchaseorder.shipremark',
                Purchaseorder__title: 'Purchaseorder.title',
                Purchaseorder__Contract__containercount: 'Purchaseorder.Contract.containercount',
                Purchaseorder__Contract__containersize_id: 'Purchaseorder.Contract.containersize_id',
                Purchaseorder__Contract__currency_id: 'Purchaseorder.Contract.currency_id',
                Purchaseorder__Contract__destinatione_id: 'Purchaseorder.Contract.destinatione_id',
                Purchaseorder__Contract__loadingport_id: 'Purchaseorder.Contract.loadingport_id',
                Purchaseorder__Contract__productorigin_id: 'Purchaseorder.Contract.productorigin_id',
                Purchaseorder__Contract__shippingport_id: 'Purchaseorder.Contract.shippingport_id',
                Purchaseorder__Contract__salescontact_id: 'Purchaseorder.Contract.salescontact_id',
                Purchaseorder__Contract__salestype_id: 'Purchaseorder.Contract.salestype_id',
                Purchaseorder__Customer__title: 'Purchaseorder.Customer.title',
                Purchaseorder__Office__title: 'Purchaseorder.Office.title',
                Purchaseorder__Supplier__title: 'Purchaseorder.Supplier.title'
            },'statePrefix'),
            ...mapGetters('buyer',{
                'findBuyerById': 'findBuyerById'
            }),
            ...mapGetters('country',{
                allCountries: 'allCountries',
                findCountryById: 'findCountryById'
            }),
            ...mapGetters('grade',{
                allGrades: 'allGrades'
            }),
            ...mapGetters('paymentterm',{
                findSupplierPaymentTermById: 'findSupplierPaymentTermById'
            }),
            ...mapGetters('salescolleague',{
                allSalesColleagues: 'allSalesColleagues',
                findSalesColleagueById: 'findSalesColleagueById'
            }),
            ...mapMultiRowFieldsPrefixed('purchaseorderitem', {
                PurchaseOrderItems: 'PurchaseOrderItems',
            },'statePrefix'),
            ...mapFieldsPrefixed('salesitem',{
                specificationNotes: 'specificationNotes'
            },'statePrefix'),
            ...mapFieldsPrefixed('purchaseorderitem',{
                itemsTotalAmount: 'itemsTotalAmount',
                itemsTotalQty: 'itemsTotalQty',
            },'statePrefix'),
            ...mapGetters('shippingport', {
                findShippingPortById: 'findShippingPortById'
            }),
            ...mapGetters('species', {
                allSpecies: 'allSpecies',
                findSpeciesById: 'findSpeciesById'
            }),
            ...mapGetters('supplier', {
                allSuppliers: 'allSuppliers',
                findSupplierById: 'findSupplierById'
            }),
            ...mapGetters([
                'buyerCaptionTypes',
                'certifications',
                'containerSizes',
                'currencies',
                'deliveryAddressTypes',
                'incoterms',
                'offices',
                'uofmQty'
            ]),
            poIncoterm() {
                let incoterm = this.incoterms.find((incoterm)=>incoterm.Incoterm.id == this.Purchaseorder__incoterm_id)?.Incoterm?.title;
                let incotermport = null;
                switch(this.Purchaseorder__incotermport){
                    case 0: //POD
                        if(this.Purchaseorder__Contract__shippingport_id != null) {
                            incotermport = ' ' + this.findShippingPortById(this.Purchaseorder__Contract__shippingport_id)?.Shippingport?.title
                        }
                        break;
                    case 1: //POL
                        if(this.Purchaseorder__Contract__loadingport_id != null) {
                            incotermport = ' ' + this.findShippingPortById(this.Purchaseorder__Contract__loadingport_id)?.Shippingport?.title
                        }
                        break;
                    case 2:
                        return null
                        break
                }
                return incoterm + incotermport;
            },
            currencyFormatPrecision () {
                return this.currencies.find((currency) => currency.Currency.id === this.Purchaseorder__currency_id)?.Currency?.formatPrecision
            },
            destinationCountry() {
                let country = this.findCountryById(this.Purchaseorder__Contract__destinatione_id)?.Country?.name
                return country
            },
            originCountry() {
                let country = this.findCountryById(this.Purchaseorder__Contract__productorigin_id)?.Country?.name
                return country
            },
            updateMode: function () {
                return this.$route.matched.some(({name}) => name === 'update_purchaseorder')
            },
        },
        methods: {
            ...mapActions('country', {
                getAllCountries: 'getAllCountries'
            }),
            ...mapActions('contract',{
                saveContract: 'saveContract'
            }),
            ...mapActions('salescolleague',{
                getAllSalesColleagues: 'getAllSalesColleagues'
            }),
            ...mapActions('supplier', {
                getAllSuppliers: 'getAllSuppliers'
            }),
            createSpecification(item){
                if(item.type == 'credit'){
                    return item.Spec.title != null ? item.Spec.title : null
                }
                let specification = null
                if (item.Species.title != null) {
                    specification = item.Species.title
                }
                if (item.Spec.title != null) {
                    specification = specification == null ? item.Spec.title : specification + ', '+ item.Spec.title
                }
                return specification
            },
            formatDate,
            formatThisNumber(value,format){
                return numberFormat(value,format)
            },
            getPurchaseOrderGrade(val){
                let grade = ''
                if(val){
                    grade = this.allGrades.find(grade=>grade.Grade.id == val)?.Grade?.title
                }
                return grade
            },
            uofmPrecision (uofm) {
                return this.uofmQty.find((u) => u.Measurement.title == uofm.trim())?.Measurement?.formatPrecision
            },
        },
        created(){
            if(this.allCountries.length == 0) this.getAllCountries();
            if(this.allSalesColleagues.length == 0) this.getAllSalesColleagues();
        }
    }
</script>

<style>
    .paper-border {
        border: dashed 1px darkgrey;
    }
    .border-top-2 {
        border-top: 2px solid black;
    }
    .border-bottom-2 {
        border-bottom: 2px solid black !important;
    }
</style>